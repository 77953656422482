import clsx from 'clsx';
import Image from 'next/image';
import { ComponentPropsWithoutRef } from 'react';
import logo from './_assets/images/logos/flashbot.png';

export function Logo(props: ComponentPropsWithoutRef<'div'>) {
  return (
    <div {...props} className={clsx('relative', props.className)}>
      <Image src={logo} alt="Salient Logo" fill />
    </div>
  );
}
