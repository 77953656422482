import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_react-dom@19._n6hazey3lz4e6bre5jgsqs47k4/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_react-dom@19._n6hazey3lz4e6bre5jgsqs47k4/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/webapps/marketing/src/app/_components/_assets/images/background-call-to-action.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/webapps/marketing/src/app/_components/_assets/images/logos/flashbot.png");
;
import(/* webpackMode: "eager", webpackExports: ["Features"] */ "/vercel/path0/webapps/marketing/src/app/_components/features.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/webapps/marketing/src/app/_components/header.tsx");
