import Link from 'next/link';
import { ComponentPropsWithoutRef } from 'react';

export function NavLink(props: ComponentPropsWithoutRef<typeof Link>) {
  return (
    <Link
      {...props}
      className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
    />
  );
}
